/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/page-context";
import usePageContent from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/usePageContent";
import { useThemeProps, TearOffDate, Heading } from "@wsui/base";
import { Fragment } from "react";

export default function EventPageHeading(props) {
  // eslint-disable-next-line no-unused-vars
  const theme = useTheme();
  props = useThemeProps({ props, name: "EventPageHeading" });
  let { ...restProps } = props;
  const {
    title,
    eventProperties: { eventOccasions },
  } = usePageContext();
  const { headingContent } = usePageContent();
  let mostRelevantOccasion = eventOccasions[0];

  return (
    title && (
      <div
        css={css`
          ${theme.styleUtils.negateMarginStart}
          ${theme.styleUtils.negateMarginEnd}
        `}
      >
        <Heading
          css={css`
            display: flex;
            gap: 0.375em;
            align-items: center;
          `}
          {...restProps}
        >
          {!!mostRelevantOccasion && (
            <Fragment>
              <TearOffDate
                date={mostRelevantOccasion.startDate}
                css={css`
                  align-self: start;
                  flex-shrink: 0;
                `}
              />{" "}
            </Fragment>
          )}
          {headingContent || title}
        </Heading>
      </div>
    )
  );
}
